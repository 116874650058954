<template>
  <v-container>
    <v-card :loading="pageLoading" class="shadow-lg mx-12">
      <div class="px-8 pt-4">
        <v-btn to="/settings/institutions" depressed class="shadow-md">
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          Go Back to Institution Listing
        </v-btn>
      </div>
      <v-card-title class="mx-4 mb-0 pb-0">
        <div>
          <h3 class="d-block mb-0">
            {{ institution._id ? 'Edit' : 'Add' }} Institution
          </h3>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <p class="ml-8 mb-0">Institution sending Common Standard Report (CRS).</p>
      <ValidationObserver ref="institutionObserver">
        <v-stepper v-model="stepper" flat>
          <v-stepper-header style="box-shadow: none">
            <v-stepper-step
              :rules="[() => stepperHasError !== 1]"
              :editable="stepper > 1 || !!institution._id"
              step="1"
              class="ml-0"
            >
              <span>Reporting Institution Details</span>
              <small v-if="stepperHasError == 1">This form is invalid</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :rules="[() => stepperHasError !== 2]"
              :editable="stepper > 2 || !!institution._id"
              step="2"
            >
              <span>Institution Address</span>
              <small v-if="stepperHasError == 2">This form is invalid</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :rules="[() => stepperHasError !== 3]"
              :editable="stepper > 3 || !!institution._id"
              step="3"
            >
              <span>Institution Defaults</span>
              <small v-if="stepperHasError == 3">This form is invalid</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="5">
              <span>Summary</span>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-content step="1">
            <v-row class="px-12">
              <v-col cols="12">
                <ValidationProvider
                  name="country of residence"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="countries"
                    item-text="name"
                    item-value="alphaISO2"
                    label="Country of Residence"
                    class="required-v"
                    :error-messages="errors"
                    v-model="institution.countryOfResidenceISOCode"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>

              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="tax identification number"
                  rules="required|max:200"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Tax Identification Number"
                    v-model="institution.taxIdentificationNumber"
                    @blur="checkIfInstitutionExist"
                    class="required-v"
                    :error-messages="taxIdExistErr || errors"
                    autocomplete="null"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="country of isssue"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="countries"
                    item-text="name"
                    item-value="alphaISO2"
                    label="Country Of Issue"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.countryOfIssue"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  name="institution type"
                  rules="required|min:3|max:120"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="entityNameTypes"
                    item-text="name"
                    item-value="code"
                    label="Institution Type"
                    :error-messages="errors"
                    v-model="institution.nameType"
                    autocomplete="null"
                  >
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" class="py-0">
                <ValidationProvider
                  name="name"
                  rules="required|min:3|max:120"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Name Of Reporting Financial Institution"
                    class="required-v"
                    @blur="checkIfInstitutionExist"
                    :error-messages="institutionNameExistErr || errors"
                    v-model="institution.name"
                    autocomplete="null"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  name="contact person"
                  rules="required|max:70"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Contact Person"
                    placeholder="John Doe"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.contactPerson"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="contact number"
                  rules="required|telephone"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Contact number"
                    placeholder="+8760000000"
                    @blur="checkIfInstitutionExist"
                    class="required-v"
                    :error-messages="contactNumberExistErr || errors"
                    v-model="institution.contactNumber"
                    autocomplete="null"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Email"
                    placeholder="company@example.com"
                    @blur="checkIfInstitutionExist"
                    class="required-v"
                    :error-messages="emailExistErr || errors"
                    v-model="institution.email"
                    autocomplete="null"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  name="report retention duration"
                  rules="required|min_value:0|max_value:720"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Report Retention Duration"
                    class="required-v"
                    :error-messages="errors"
                    v-model="institution.reportRetentionDuration"
                    :suffix="`Month${
                      institution.reportRetentionDuration > 1 ? 's' : ''
                    }`"
                    autocomplete="null"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-card-actions class="mt-4">
              <v-spacer></v-spacer>
              <v-btn text @click="onBack(false)"> Cancel </v-btn>
              <v-btn color="primary" @click="onNext"> Next </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row class="px-12">
              <v-col cols="12">
                <ValidationProvider
                  name="address type"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="addressTypes"
                    item-text="name"
                    item-value="code"
                    label="Address Type"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.addressType"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <ValidationProvider
                  name="street"
                  rules="required|max:200"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Street"
                    :error-messages="errors"
                    v-model="institution.street"
                    class="required-v"
                    autocomplete="null"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" md="12" class="py-0">
                <ValidationProvider
                  name="city"
                  rules="required|max:200"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="City"
                    :error-messages="errors"
                    v-model="institution.city"
                    class="required-v"
                    autocomplete="null"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="country"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="countries"
                    :item-text="'name'"
                    :item-value="'alphaISO2'"
                    class="required-v"
                    label="Country"
                    :error-messages="errors"
                    v-model="institution.country"
                    @change="onAddressChanged"
                    autocomplete="null"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="province"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="institutionProvinces"
                    label="Province/State"
                    class="required-v"
                    :error-messages="errors"
                    v-model="institution.countrySubEntity"
                    autocomplete="null"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="postal code"
                  rules="required|max:200"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :error-messages="errors"
                    v-model="institution.postalCode"
                    autocomplete="null"
                  >
                    <template #label>
                      Postal Code
                      <span class="red--text"><strong>* </strong></span>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="post office box"
                  rules="max:200"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Post Office Box"
                    :error-messages="errors"
                    v-model="institution.postOfficeBox"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="building identifier"
                  rules="max:200"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Building Identifier"
                    :error-messages="errors"
                    v-model="institution.buildingIdentifier"
                    autocomplete="null"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="suite identifier"
                  rules="max:200"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Suite Identifier"
                    :error-messages="errors"
                    v-model="institution.suiteIdentifier"
                    autocomplete="null"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" md="6" class="py-0">
                <ValidationProvider
                  name="floor identifier"
                  rules="max:200"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Floor Identifier"
                    :error-messages="errors"
                    v-model="institution.floorIdentifier"
                    autocomplete="null"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-card-actions class="mt-4">
              <v-spacer></v-spacer>
              <v-btn text @click="onBack(true)"> Back </v-btn>
              <v-btn color="primary" @click="onNext"> Next </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="3">
            <div class="px-12">
              <v-alert type="info"
                >Please review these settings to ensure that the assumptions are
                correct.</v-alert
              >
            </div>
            <div class="px-12 mb-6">
              <h3>Global Default Settings</h3>
              <p>
                These values will be used in the event these are not provided
                for any accounts
              </p>
              <v-divider></v-divider>
            </div>
            <v-row class="px-12 mb-8">
              <v-col cols="12">
                <ValidationProvider
                  name="default currency"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="currencies"
                    item-text="name"
                    item-value="code"
                    label="Default Currency"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.defaultCurrency"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="px-12 mb-6">
              <h3>Individual Account Default Settings</h3>
              <p>
                These values will be used in the event these are not provided
                for the individual accounts
              </p>
              <v-divider></v-divider>
            </div>
            <v-row class="px-12">
              <v-col cols="6">
                <ValidationProvider
                  name="default individual name type"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="nameTypes"
                    item-text="name"
                    item-value="code"
                    label="Default Name Type"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.defaultIndividualNameType"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  name="default individual address type"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="addressTypes"
                    item-text="name"
                    item-value="code"
                    label="Default Address Type"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.defaultIndividualAddressType"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  name="default individual account number type"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="accountNumberTypes"
                    item-text="name"
                    item-value="code"
                    label="Default Account Number Type"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.defaultIndividualAccountNumberType"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="px-12 my-6">
              <h3>Organization Account Default Settings</h3>
              <p>
                These values will be used in the event these are not provided
                for the organization accounts
              </p>
              <v-divider></v-divider>
            </div>
            <v-row class="px-12">
              <v-col cols="6">
                <ValidationProvider
                  name="default organization name type"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="nameTypes"
                    item-text="name"
                    item-value="code"
                    label="Default Name Type"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.defaultOrganizationNameType"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  name="default organization address type"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="addressTypes"
                    item-text="name"
                    item-value="code"
                    label="Default Address Type"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.defaultOrganizationAddressType"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  name="default account holder type"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="accountHolderTypes"
                    item-text="name"
                    item-value="code"
                    label="Default Account Holder Type"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.defaultAccountHolderType"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  name="default organizaton account number type"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="accountNumberTypes"
                    item-text="name"
                    item-value="code"
                    label="Default Account Number Type"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.defaultOrganizationAccountNumberType"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  name="default controlling person type"
                  rules="required"
                  events="['blur']"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :items="controllingPersonTypes"
                    item-text="name"
                    item-value="code"
                    label="Default Controlling Person Type"
                    :error-messages="errors"
                    class="required-v"
                    v-model="institution.defaultControllingPersonType"
                    autocomplete="null"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-card-actions class="mt-4">
              <v-spacer></v-spacer>
              <v-btn text @click="onBack(true)"> Back </v-btn>
              <v-btn color="primary" @click="onNext"> Next </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-list flat subheader three-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1"> mdi-domain </v-icon> Reporting
                    Institution Details</v-list-item-title
                  >
                  <v-list-item-subtitle
                    class="text-body-1 text-weight-bold mt-1"
                  >
                    {{ institution.name }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="text-body-1"
                    >Country of Residence:
                    {{
                      institution.countryOfResidenceISOCode
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="text-body-1"
                    >Tax Identification Number:
                    {{
                      institution.taxIdentificationNumber || 'N/A'
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="text-body-1"
                    >Country Of Issue:
                    {{
                      institution.countryOfIssue || 'N/A'
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title
                    ><v-icon class="mr-1"> mdi-contacts-outline </v-icon>
                    Contact Details</v-list-item-title
                  >
                  <v-list-item-subtitle
                    class="text-body-1 text-weight-bold mt-1"
                    >{{ institution.contactPerson }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="text-body-1"
                    >Tel: {{ institution.contactNumber }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="text-body-1"
                    >Email: {{ institution.email }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1"> mdi-map-marker-outline </v-icon>
                    Address</v-list-item-title
                  >
                  <address class="text-mute">
                    {{ institution.addressType }}, <br />
                    <span v-if="institution.postOfficeBox"
                      >{{ institution.postOfficeBox }} <br
                    /></span>
                    {{ institution.street }},
                    <span v-if="institution.buildingIdentifier">
                      {{ institution.buildingIdentifier }},</span
                    >
                    <span v-if="institution.suiteIdentifier"
                      >{{ institution.suiteIdentifier }}<br
                    /></span>
                    {{ institution.city }}, <br />
                    {{ institution.country }},
                    {{ institution.countrySubEntity }},
                    {{ institution.postalCode }}
                  </address>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions class="mt-4">
              <v-spacer></v-spacer>
              <v-btn text @click="onBack(true)"> Back </v-btn>
              <v-btn color="primary" @click="onSubmit"> Save </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper>
      </ValidationObserver>
    </v-card>

    <!-- Modals -->
    <ConfirmationModal
      ref="activeToggleConfirmationModal"
      :title="confirmationTitle"
      subtitle=""
      :message="confirmationMessage"
      confirmColor="error darken-1"
    />
  </v-container>
</template>

<script>
import PageMixin from '@/mixins/page.mixin';
import { mapActions, mapGetters } from 'vuex';
import { VuetifyMoneyOptions } from '@/lib/constants/options';

export default {
  name: 'LoanForm',
  mixins: [PageMixin],
  data: () => ({
    pageLoading: true,
    VuetifyMoneyOptions: VuetifyMoneyOptions,
    stepper: 1,
    stepperHasError: 0,
    institution: {},
    confirmationTitle: '',
    confirmationMessage: '',
    links: [
      {
        text: 'Dashboard',
        disabled: false,
        href: 'breadcrumbs_dashboard'
      },
      {
        text: 'New Loan',
        disabled: true,
        href: 'breadcrumbs_link_2'
      }
    ],

    firstStepperGroupValidations: [
      'tax identification number',
      'institution type',
      'report retention duration',
      'country of issue',
      'country of residence',
      'name',
      'contact person',
      'contact number',
      'email'
    ],
    secondStepperGroupValidations: [
      'address type',
      'street',
      'building identifier',
      'suite identifier',
      'city',
      'country',
      'postal code',
      'post office box',
      'floor identifier',
      'province'
    ],
    thirdStepperGroupValidations: [
      'default individual name type',
      'default individual address type',
      'default individual account number type',
      'default organization name type',
      'default organization address type',
      'default organization account number type',
      'default account holder type',
      'default controlling person type'
    ],
    emailExistErr: '',
    taxIdExistErr: '',
    contactNumberExistErr: '',
    institutionNameExistErr: '',
    institutionProvinces: []
  }),

  async mounted() {
    try {
      this.pageLoading = true;
      const { id } = this.$route.params;
      if (id) {
        this.institution = await this.findInstitution(id);
        if (this.institution) {
          this.onAddressChanged();
        }
      } else {
        this.institution = {
          nameType: 'OECD207',
          defaultIndividualNameType: 'OECD202',
          defaultIndividualAddressType: 'OECD302',
          defaultIndividualAccountNumberType: 'OECD602',
          defaultOrganizationNameType: 'OECD207',
          defaultOrganizationAddressType: 'OECD303',
          defaultAccountHolderType: 'CRS102',
          defaultOrganizationAccountNumberType: 'OECD602',
          defaultControllingPersonType: 'CRS801'
        };
      }
    } catch (error) {
      this.httpErrorHandler(error);
    } finally {
      this.pageLoading = false;
    }
  },

  computed: {
    ...mapGetters('coreModule', [
      'currencies',
      'countries',
      'addressTypes',
      'nameTypes',
      'accountHolderTypes',
      'accountNumberTypes',
      'controllingPersonTypes'
    ]),
    ...mapGetters('institutionModule', ['institutions']),

    entityNameTypes() {
      return this.nameTypes.filter((el, i) => i > 3);
    }
  },

  methods: {
    ...mapActions('institutionModule', [
      'createInstitution',
      'updateInstitution',
      'findInstitution',
      'fetchInstitution'
    ]),

    ...mapActions('coreModule', ['getCountryProvinces']),

    async onAddressChanged() {
      this.institutionProvinces = await this.getCountryProvinces({
        key: this.institution.country
      });
    },

    checkIfInstitutionExist() {
      this.emailExistErr =
        this.taxIdExistErr =
        this.contactNumberExistErr =
        this.institutionNameExistErr =
          '';
      let exist = this.institutions.some(
        (el) =>
          el.name === this.institution.name && this.institution._id !== el._id
      );
      if (exist) {
        this.institutionNameExistErr = `Institution with the name ${this.institution.name} already exist`;
      }

      exist = this.institutions.some(
        (el) =>
          el.contactNumber === this.institution.contactNumber &&
          this.institution._id !== el._id
      );
      if (exist) {
        this.contactNumberExistErr = `Institution with the contact number ${this.institution.contactNumber} already exist`;
      }

      exist = this.institutions.some(
        (el) =>
          el.taxIdentificationNumber ===
            this.institution.taxIdentificationNumber &&
          this.institution._id !== el._id
      );
      if (exist) {
        this.taxIdExistErr = `Institution with the tax number ${this.institution.taxIdentificationNumber} already exist`;
      }

      exist = this.institutions.some(
        (el) =>
          el.email === this.institution.email && this.institution._id !== el._id
      );
      if (exist) {
        this.emailExistErr = `Institution with the email ${this.institution.email} already exist`;
      }
    },

    async onBack(type) {
      if (this.stepper == 1 || !type) {
        this.confirmationTitle = `You are about to cancel the creation of this institution.`;
        this.confirmationMessage = `Are you sure you want to carry out this action?`;

        return this.$refs.activeToggleConfirmationModal.show(async () => {
          this.$router.replace('/settings/institutions');
        });
      }

      this.stepper--;
    },

    async onNext() {
      if (this.stepper == 3) {
        const isValid = await this.$refs.institutionObserver.validate();
        if (
          !isValid ||
          this.emailExistErr ||
          this.taxIdExistErr ||
          this.contactNumberExistErr ||
          this.institutionNameExistErr
        ) {
          this.showSnack({
            color: 'warning',
            title: `Invalid Form.`,
            message: `Please check form before proceeding.`
          });

          const errors = this.$refs.institutionObserver.errors;

          let hasError =
            this.firstStepperGroupValidations.some((key) => {
              return errors && errors[key]?.length > 0;
            }) ||
            this.emailExistErr ||
            this.taxIdExistErr ||
            this.contactNumberExistErr ||
            this.institutionNameExistErr ||
            false;

          if (hasError) {
            this.stepper = 1;
            this.stepperHasError = 1;
            return;
          }

          hasError = this.secondStepperGroupValidations.some((key) => {
            return errors && errors[key]?.length > 0;
          });
          if (hasError) {
            this.stepper = 2;
            this.stepperHasError = 2;
            return;
          }

          hasError = this.thirdStepperGroupValidations.some((key) => {
            return errors && errors[key]?.length > 0;
          });
          if (hasError) {
            this.stepper = 3;
            this.stepperHasError = 3;
            return;
          }
        }
      }

      this.stepper++;
    },

    async onSubmit() {
      const isUpdating = !!this.institution._id;

      try {
        this.institution.reportRetentionDuration = Number(
          this.institution.reportRetentionDuration
        );
        if (!isUpdating) {
          this.institution.isActive = true;
          await this.createInstitution(this.institution);
        } else {
          await this.updateInstitution({
            id: this.institution._id,
            payload: this.institution
          });
        }

        this.showSnack({
          color: 'success',
          title: `${isUpdating ? 'Updated' : 'Created'}`,
          message: `Institution was successfully ${
            isUpdating ? 'updated' : 'created.'
          }.`
        });

        this.$router.push('/settings/institutions');
      } catch (error) {
        this.httpErrorHandler(error);
        throw error;
      }
    }
  }
};
</script>
<style scoped>
.required-v >>> .v-label::after {
  content: ' *' !important;
  color: red !important;
}
</style>